$COL1: #ffffff; //
$COLFLAT1: #ffffff; //
$COL2: #4597e0; //
$COLFLAT2: #4597e0; //
$COL3: #fadb05; //
$COLFLAT3: #fadb05; //
$COL4: #1b1b1b; //
$COLFLAT4: #1b1b1b; //
$COL5: #f2f2f2; //
$COLFLAT5: #f2f2f2; //
$COL6: #2c1821; //
$COLFLAT6: #2c1821; //
$COL7: #1b1b1b; //
$COLFLAT7: #1b1b1b; //
$COL8: #1b1b1b; //
$COLFLAT8: #1b1b1b; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #1b1b1b; //
$COLFLAT11: #1b1b1b; //
$COL12: #1b1b1b; //
$COLFLAT12: #1b1b1b; //
$COL13: #1b1b1b; //
$COLFLAT13: #1b1b1b; //
$COL14: #1b1b1b; //
$COLFLAT14: #1b1b1b; //
$COL15: #1b1b1b; //
$COLFLAT15: #1b1b1b; //
$COL16: #1b1b1b; //
$COLFLAT16: #1b1b1b; //
$COL17: #1b1b1b; //
$COLFLAT17: #1b1b1b; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: rgba(255,255,255,0.38); //White transparent
$COLFLAT21: #b0b0b0; //White transparent
$COL22: #1b1b1b; //DARKER
$COLFLAT22: #1b1b1b; //DARKER
$COL23: rgba(0,0,0,0); //
$COLFLAT23: #808080; //
$COL24: #e8e8e8; //
$COLFLAT24: #e8e8e8; //
$COL25: #fadb05; //
$COLFLAT25: #fadb05; //
$COL26: #11f29b; //Green
$COLFLAT26: #11f29b; //Green
$COL27: #f21166; //Green
$COLFLAT27: #f21166; //Green
$COL28: #1b1b1b; //
$COLFLAT28: #1b1b1b; //
$COL29: rgba(0,0,0,0.6); //black 60%
$COLFLAT29: #333333; //black 60%
$COL30: #938266; //Coffee
$COLFLAT30: #938266; //Coffee
$COL31: #e9e8ed; //Propios Grey
$COLFLAT31: #e9e8ed; //Propios Grey
$COL32: #d3003d; //Ventou Pink
$COLFLAT32: #d3003d; //Ventou Pink
$COL33: #4a9fd3; //Devakon Blue
$COLFLAT33: #4a9fd3; //Devakon Blue
$COL34: #28afce; //DMD Blue
$COLFLAT34: #28afce; //DMD Blue
$COL35: #acb22d; //Teledent Green
$COLFLAT35: #acb22d; //Teledent Green
$COL36: #fa8621; //Longrange orange
$COLFLAT36: #fa8621; //Longrange orange
$COL37: #6e9246; //CherryHill Green
$COLFLAT37: #6e9246; //CherryHill Green
$COL38: #b01a2b; //Road and Track red
$COLFLAT38: #b01a2b; //Road and Track red
$COL39: #08a168; //Abbin Green
$COLFLAT39: #08a168; //Abbin Green
$COL40: #4eb3de; //The Print Co Blue
$COLFLAT40: #4eb3de; //The Print Co Blue
$COL41: rgba(255,255,255,0.2); //Trans white form field
$COLFLAT41: #999999; //Trans white form field
$COL42: #ef3244; //PGAV Red
$COLFLAT42: #ef3244; //PGAV Red
$COL43: #5786a5; //Aqua kitchen
$COLFLAT43: #5786a5; //Aqua kitchen
$COL44: #1b1953; //Navy Blue
$COLFLAT44: #1b1953; //Navy Blue
$COL45: #1b1b1b; //
$COLFLAT45: #1b1b1b; //
$COL46: #1b1b1b; //
$COLFLAT46: #1b1b1b; //
$COL47: #e3dccb; //Beige
$COLFLAT47: #e3dccb; //Beige
$COL48: #006ebd; //Pinboards Blue
$COLFLAT48: #006ebd; //Pinboards Blue
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
