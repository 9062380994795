body { background-color: $COL1; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 10px; }

@media #{$medium-up} {
[data-pad="0"] { margin-top: 20px; }

}
[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL12;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 30px;

}
}
h2 {
color: $COL13;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 26px;

}
}
h3 {
color: $COL14;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
}
h4 {
color: $COL15;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL16;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h6 {
color: $COL17;
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Poppins';
font-weight: 500;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-author {
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul {
color: $COL11;
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL28;
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 25px;

}
}
summary {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
body {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: $COL8}
a:hover {color: $COL25}
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL9;
 }
/* Primary:4 */
.MES4 {
background-color: $COL2;
color: $COL9;
 }
.MES4 {
background-color: $COL2;
color: $COL9;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL9;
 }
 }
cite.MEC4{
color: $COL9;
}
/* Secondary:5 */
.MES5 {
background-color: $COL3;
&:hover { background-color: $COL22;}
color: $COL10;
&:hover { color: $COL20;}
border-radius: 100%;
padding: 10px 20px;

 }
/* Secondary:6 */
.MES6 {
background-color: $COL3;
color: $COL10;
 }
.MES6 {
background-color: $COL3;
color: $COL10;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL10;
 }
 }
cite.MEC6{
color: $COL10;
}
/* Dark:7 */
.MES7 {
background-color: $COL4;
color: $COL18;
 }
/* Dark:8 */
.MES8 {
background-color: $COL4;
color: $COL1;
 }
.MES8 {
background-color: $COL4;
color: $COL1;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL1;
 }
 }
a.MEC8 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC8{
color: $COL1;
}
/* Light:9 */
.MES9 {
background-color: $COL5;
color: $COL45;
 }
/* Light:10 */
.MES10 {
background-color: $COL5;
color: $COL45;
 }
.MES10 {
background-color: $COL5;
color: $COL45;
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: $COL45;
 }
 }
cite.MEC10{
color: $COL45;
}
/* Shade 1:11 */
.MES11 {
background-color: $COL6;
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL6;
 }
.MES12 {
background-color: $COL6;
 }
/* Menu:13 */
.MES13 {
background-color: $COL21;
color: $COL4;
font-size: 16px;
padding: 15px 0;

 }
.MES13 {
background-color: $COL21;
color: $COL4;
font-size: 16px;
padding: 15px 0;

 }
a.MEC13 { color: $COL22;
&:hover { color: $COL3; }
 }
cite.MEC13{
color: $COL4;
font-size: 16px;
}
/* home block blue:14 */
.MES14 {
background-color: $COL22;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cpcontacts.josephsaba.com.au/img/376/64');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
 }
.MES14 {
background-color: $COL22;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cpcontacts.josephsaba.com.au/img/376/64');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL20;
 }
 }
a.MEC14 { color: $COL20;
&:hover { color: $COL27; }
 }
.MEC14 li {color: $COL20;}
cite.MEC14{
color: $COL20;
}
/* :15 */
.MES15 {
background-color: $COL20;
color: $COL4;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL3 transparent transparent transparent;
 }
.MES15 {
background-color: $COL20;
color: $COL4;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL3 transparent transparent transparent;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL4;
 }
 }
.MEC15 li {color: $COL4;}
cite.MEC15{
color: $COL4;
}
/* Hollaw:16 */
.MES16 {
color: $COL20;
&:hover { color: $COL20;}
border-width: 2px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }
/* :17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: $COL20;
&:hover, &.hover { background-color: $COL20;}
color: $COL4;
font-size: 16px;
 }
.MES18 {
background-color: $COL20;
&:hover, &.hover { background-color: $COL20;}
color: $COL4;
font-size: 16px;
 }
a.MEC18 { color: $COL4;
&:hover { color: $COL4; }
 }
cite.MEC18{
color: $COL4;
font-size: 16px;
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL22;
 }
h1.MEC19 { @media #{$medium-up} { font-size: 33px; }; }
h1.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h2.MEC19 { @media #{$medium-up} { font-size: 28.6px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 31.2px; }; }
h3.MEC19 { @media #{$medium-up} { font-size: 26.4px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 28.8px; }; }
h4.MEC19 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$medium-up} { font-size: 27.5px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC19 { @media #{$medium-up} { font-size: 19.8px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 21.6px; }; }
 }
/* Footer Dark:20 */
.MES20 {
background-color: $COL22;
color: $COL1;
font-size: 12.8px;
 }
.MES20 {
background-color: $COL22;
color: $COL1;
font-size: 12.8px;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL1;
 }
 }
a.MEC20 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC20{
color: $COL1;
font-size: 12.8px;
}
/* News grid:21 */
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
 }
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: $COL22;
 }
 }
cite.MEC21{
color: $COL4;
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: $COL5;
}
font-size: 19.2px;
& > article { font-size: 19.2px;
 }
& > summary{padding: 10px 20px;}

& > article{padding: 15px;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: $COL2;
}
color: $COL20;
& > article { color: $COL20;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Secondary:24 */
.MES24 {
color: $COL22;
border-width: 2px;
border-style: solid;
border-color: $COL20;
 }
/* Shade 2:25 */
.MES25 {
background-color: $COL24;
 }
/* Shade 2:26 */
.MES26 {
background-color: $COL24;
 }
.MES26 {
background-color: $COL24;
 }
/* Menu top:27 */
nav.me-Menu.MES27 {
& .menu-item.MEC27, & .menu-item.MEC27 > div.MEC27{ & > a.MEC27{color: $COL22;
text-transform: uppercase;
}
 &:hover > a.MEC27{color: $COL3;
}
 }
&.horizontal > .menu-item.MEC27 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC27 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC27 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC27 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Green Button:28 */
.MES28 {
background-color: $COL26;
&:hover { background-color: $COL22;}
color: $COL10;
&:hover { color: $COL20;}
border-radius: 100px;
padding: 10px 20px;

 }
/* Raspberry Button:29 */
.MES29 {
background-color: $COL27;
&:hover { background-color: $COL22;}
color: $COL10;
&:hover { color: $COL20;}
border-radius: 100px;
padding: 14px 20px;

 }
/* Raspberry:30 */
.MES30 {
background-color: $COL27;
color: $COL20;
font-size: 16px;
 }
.MES30 {
background-color: $COL27;
color: $COL20;
font-size: 16px;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: $COL5;
 }
 }
cite.MEC30{
color: $COL20;
font-size: 16px;
}
/* :31 */
nav.responsive-menu {
.menu-item.MEC31{background-color: $COL3;
}
& .menu-item.MEC31, & .menu-item.MEC31 > div.menu-item-wrap{ & > a.MEC31, & > i{color: $COL20;
font-size: 24px;
@media #{$medium-up} {
font-size: 32px;
}text-align: left;
}
  }
& .menu-item.MEC31 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 15px;}

& .sub-menu{ .sub-menu {}}

 }
/* Footer 2:32 */
.MES32 {
color: $COL4;
font-size: 11.2px;
padding: 10px 0;

 }
.MES32 {
color: $COL4;
font-size: 11.2px;
padding: 10px 0;

h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: $COL4;
 }
 }
cite.MEC32{
color: $COL4;
font-size: 11.2px;
}
/* Secondary:33 */
.MES33 {
background-color: $COL3;
&:hover { background-color: $COL22;}
color: $COL4;
&:hover { color: $COL20;}
border-radius: 100px;
padding: 10px 20px;

 }
/* padding home placeholder:34 */
.MES34 {
& > hr {border-width: 120px 0 0 0;}
& > hr {border-color: $COL1;}
& > hr {border-top-style: solid;}
 }
/* Grey home panel:35 */
.MES35 {
padding: 0 0 120px 0;

 }
.MES35 {
padding: 0 0 120px 0;

 }
/* Home spacer:36 */
.MES36 {
padding: 10px;

 }
/* Bg tile:37 */
.MES37 {
background-color: $COL5;
padding: 20px 0;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES37 {
background-color: $COL5;
padding: 20px 0;

@media #{$medium-up} {
padding: 20px;

}
 }
/* Button ouline:38 */
.MES38 {
color: $COL4;
&:hover { color: $COL3;}
padding: 15px;

border-width: 2px;
border-style: solid;
border-color: $COL4;
 }
/* Menu footer:39 */
nav.me-Menu.MES39 {
& .menu-item.MEC39, & .menu-item.MEC39 > div.MEC39{ & > a.MEC39{color: $COL1;
text-transform: uppercase;
}
 &:hover > a.MEC39{color: $COL3;
}
 }
&.horizontal > .menu-item.MEC39 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC39 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC39 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC39 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Overlay Dark:40 */
.MES40 {
background-color: $COL29;
color: $COL1;
 }
.MES40 {
background-color: $COL29;
color: $COL1;
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: $COL1;
 }
h1.MEC40 { font-size: 20.8px; }
h1.MEC40 { @media #{$medium-up} { font-size: 18px; }; }
h1.MEC40 { @media #{$large-up} { font-size: 24px; }; }
h2.MEC40 { font-size: 17.6px; }
h2.MEC40 { @media #{$medium-up} { font-size: 15.6px; }; }
h2.MEC40 { @media #{$large-up} { font-size: 20.8px; }; }
h3.MEC40 { font-size: 16px; }
h3.MEC40 { @media #{$medium-up} { font-size: 14.4px; }; }
h3.MEC40 { @media #{$large-up} { font-size: 19.2px; }; }
h4.MEC40 { font-size: 17.6px; }
h4.MEC40 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC40 { @media #{$large-up} { font-size: 20px; }; }
h5.MEC40 { font-size: 14.4px; }
h5.MEC40 { @media #{$medium-up} { font-size: 15px; }; }
h5.MEC40 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC40 { font-size: 12.8px; }
h6.MEC40 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC40 { @media #{$large-up} { font-size: 14.4px; }; }
 }
cite.MEC40{
color: $COL1;
}
/* White Text:41 */
.MES41 {
color: $COL1;
 }
.MES41 {
color: $COL1;
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: $COL1;
 }
 }
cite.MEC41{
color: $COL1;
}
/* Black Text:42 */
.MES42 {
color: $COL4;
 }
.MES42 {
color: $COL4;
h1.MEC42, h2.MEC42, h3.MEC42, h4.MEC42, h5.MEC42, h6.MEC42 { color: $COL4;
 }
 }
cite.MEC42{
color: $COL4;
}
/* Coffee:43 */
.MES43 {
background-color: $COL30;
color: $COL20;
font-size: 16px;
 }
.MES43 {
background-color: $COL30;
color: $COL20;
font-size: 16px;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: $COL5;
 }
 }
cite.MEC43{
color: $COL20;
font-size: 16px;
}
/* Propis Grey:44 */
.MES44 {
background-color: $COL31;
color: $COL20;
font-size: 16px;
 }
.MES44 {
background-color: $COL31;
color: $COL20;
font-size: 16px;
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: $COL5;
 }
 }
cite.MEC44{
color: $COL20;
font-size: 16px;
}
/* Ventou pink:45 */
.MES45 {
background-color: $COL32;
color: $COL20;
font-size: 16px;
 }
.MES45 {
background-color: $COL32;
color: $COL20;
font-size: 16px;
h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: $COL5;
 }
 }
cite.MEC45{
color: $COL20;
font-size: 16px;
}
/* Image rollover:46 */
.MES46 {
color: $COL20;
 }
.MES46 {
color: $COL20;
h1.MEC46, h2.MEC46, h3.MEC46, h4.MEC46, h5.MEC46, h6.MEC46 { color: $COL1;
 }
 }
a.MEC46 { color: $COL20;
 }
.MEC46 li {color: $COL20;}
cite.MEC46{
color: $COL20;
}
/* Devakon Blue:47 */
.MES47 {
background-color: $COL33;
color: $COL20;
 }
.MES47 {
background-color: $COL33;
color: $COL20;
h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: $COL5;
 }
 }
cite.MEC47{
color: $COL20;
}
/* DMD Blue:48 */
.MES48 {
background-color: $COL34;
color: $COL20;
font-size: 16px;
 }
.MES48 {
background-color: $COL34;
color: $COL20;
font-size: 16px;
h1.MEC48, h2.MEC48, h3.MEC48, h4.MEC48, h5.MEC48, h6.MEC48 { color: $COL5;
 }
 }
cite.MEC48{
color: $COL20;
font-size: 16px;
}
/* Left Button:49 */
.MES49 {
&:hover { background-color: $COL4;}
color: $COL22;
&:hover { color: $COL20;}
font-size: 14.4px;
border-radius: 100px 0 0 100px;
padding: 5px 10px 5px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL4;
 }
/* Right Button:50 */
.MES50 {
&:hover { background-color: $COL4;}
color: $COL4;
&:hover { color: $COL20;}
font-size: 14.4px;
border-radius: 0 100px 100px 0;
padding: 5px 20px 5px 10px;

border-width: 1px;
border-style: solid;
border-color: $COL4;
 }
/* White Text:51 */
.MES51 {
color: $COL1;
 }
.MES51 {
color: $COL1;
h1.MEC51, h2.MEC51, h3.MEC51, h4.MEC51, h5.MEC51, h6.MEC51 { color: $COL1;
 }
 }
cite.MEC51{
color: $COL1;
}
/* Teledent Green:53 */
.MES53 {
background-color: $COL35;
color: $COL20;
font-size: 16px;
 }
.MES53 {
background-color: $COL35;
color: $COL20;
font-size: 16px;
h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: $COL5;
 }
 }
cite.MEC53{
color: $COL20;
font-size: 16px;
}
/* Longrange Orange:54 */
.MES54 {
background-color: $COL36;
color: $COL20;
 }
.MES54 {
background-color: $COL36;
color: $COL20;
h1.MEC54, h2.MEC54, h3.MEC54, h4.MEC54, h5.MEC54, h6.MEC54 { color: $COL5;
 }
 }
cite.MEC54{
color: $COL20;
}
/* CheryyHill Green:55 */
.MES55 {
background-color: $COL37;
color: $COL20;
font-size: 16px;
 }
.MES55 {
background-color: $COL37;
color: $COL20;
font-size: 16px;
h1.MEC55, h2.MEC55, h3.MEC55, h4.MEC55, h5.MEC55, h6.MEC55 { color: $COL5;
 }
 }
cite.MEC55{
color: $COL20;
font-size: 16px;
}
/* Road and Track Red:56 */
.MES56 {
background-color: $COL38;
color: $COL20;
 }
.MES56 {
background-color: $COL38;
color: $COL20;
h1.MEC56, h2.MEC56, h3.MEC56, h4.MEC56, h5.MEC56, h6.MEC56 { color: $COL5;
 }
 }
cite.MEC56{
color: $COL20;
}
/* Abbin Green:57 */
.MES57 {
background-color: $COL39;
color: $COL20;
font-size: 16px;
 }
.MES57 {
background-color: $COL39;
color: $COL20;
font-size: 16px;
h1.MEC57, h2.MEC57, h3.MEC57, h4.MEC57, h5.MEC57, h6.MEC57 { color: $COL5;
 }
 }
cite.MEC57{
color: $COL20;
font-size: 16px;
}
/* The Print Co:58 */
.MES58 {
background-color: $COL40;
color: $COL20;
 }
.MES58 {
background-color: $COL40;
color: $COL20;
h1.MEC58, h2.MEC58, h3.MEC58, h4.MEC58, h5.MEC58, h6.MEC58 { color: $COL5;
 }
 }
cite.MEC58{
color: $COL20;
}
/* Email label shade 2:61 */
.MES61 {
background-color: $COL24;
border-width: 0 1px 0 0;
border-style: solid;
border-color: $COL1;
 }
.MES61 {
background-color: $COL24;
border-width: 0 1px 0 0;
border-style: solid;
border-color: $COL1;
 }
/* PGAV Red:62 */
.MES62 {
background-color: $COL42;
color: $COL20;
font-size: 16px;
 }
.MES62 {
background-color: $COL42;
color: $COL20;
font-size: 16px;
h1.MEC62, h2.MEC62, h3.MEC62, h4.MEC62, h5.MEC62, h6.MEC62 { color: $COL5;
 }
 }
cite.MEC62{
color: $COL20;
font-size: 16px;
}
/* White mobile device:63 */
.MES63 {
background-color: $COL20;
border-radius: 25px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 50px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES63 {
background-color: $COL20;
border-radius: 25px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 50px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Kitchen Wholesaler:64 */
.MES64 {
background-color: $COL43;
color: $COL1;
 }
.MES64 {
background-color: $COL43;
color: $COL1;
h1.MEC64, h2.MEC64, h3.MEC64, h4.MEC64, h5.MEC64, h6.MEC64 { color: $COL1;
 }
 }
cite.MEC64{
color: $COL1;
}
/* Navy Blue:65 */
.MES65 {
background-color: $COL44;
color: $COL1;
 }
.MES65 {
background-color: $COL44;
color: $COL1;
 }
cite.MEC65{
color: $COL1;
}
/* Beige:66 */
.MES66 {
background-color: $COL47;
color: $COL22;
 }
.MES66 {
background-color: $COL47;
color: $COL22;
h1.MEC66, h2.MEC66, h3.MEC66, h4.MEC66, h5.MEC66, h6.MEC66 { color: $COL22;
 }
 }
cite.MEC66{
color: $COL22;
}
/* Pinboards Blue:67 */
.MES67 {
background-color: $COL48;
color: $COL20;
 }
.MES67 {
background-color: $COL48;
color: $COL20;
h1.MEC67, h2.MEC67, h3.MEC67, h4.MEC67, h5.MEC67, h6.MEC67 { color: $COL1;
 }
 }
cite.MEC67{
color: $COL20;
}
/* Grey Outline:68 */
.MES68 {
border-width: 1px;
border-style: solid;
border-color: $COL24;
 }
.MES68 {
border-width: 1px;
border-style: solid;
border-color: $COL24;
 }
